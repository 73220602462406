/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


@media (min-width: 1400px) {
    .container {
         max-width: 1320px;
    }
}

.customColumn
{
  padding: 10px !important;
}

/* https://github.com/Semantic-Org/Semantic-UI/issues/6267 */
/* .ui.modal:not(.compact) {
  width: calc(100% - 50px) !important;
  margin-left: 25px !important;
  height: calc(100% - 50px) !important;
  margin-top: 25px !important;
  } */

.ui.modal:not(.compact) {
  width: 500px !important;
  margin-left: 25px !important;
  height: calc(100% - 50px) !important;
  margin-top: 25px !important;
}

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}
    
.iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}