a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navBarDarkTheme
{
  background-color: black !important;
  color:white !important;
}

.navBarDarkToggleTheme
{
  background-color: white !important;
}

.navBarLogoutButton
{
  border:0px !important;
  line-height: 1.5 !important;
}