#Slider {
  background-color: lightslategrey;
  position: absolute;
  border: 2px solid black;
  border-radius: 15px;
  width: 325px;
  height:200px;
  padding: 10px;
}

#Slider.slideIn { 
  opacity: 1;
  transform: translateX(0);
  transition: all 1.2s ease;
}

#Slider.slideOut { 
  transform: translateX(-100%);
  border: 0px;
  opacity: 0;
  transition: all 1.2s ease;
}